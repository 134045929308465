import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Pagination } from "../../components";
import { Dropdown } from "../../constants/images";
import { setSelectedAgentId, setShowAgent } from "../../redux/slice/modalOverlay";
// import AgentTableDetails from "./_components/agent-details";
import AgentNavbar from "./_components/navbar";
import { formatDateSuffix } from "../../utils/getDaySuffix";
import {
  useGetAgentReferralsQuery,
  useGetVendorDetailsQuery,
} from "../business/_components/agents/_api";

const entries = [
  { id: 0, entry: 10 },
  { id: 1, entry: 20 },
  { id: 2, entry: 30 },
];

const ContractorAgents = () => {
  const dispatch = useDispatch();
  const [selectedEntry, setSelectedEntry] = useState(10);
  const [selected, setSelected] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [openSelect, setOpenSelect] = useState(false);
  const [userDetailsData, setUserDetailsData] = useState(null);

  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const showAgent = useSelector((state) => state.modalOverlay.states.showAgent);
  const agentSearch = useSelector((state) => state.modalOverlay.states.agentSearch);
  const filter = useSelector((state) => state.modalOverlay.states.selectedReferral);

  const decodedToken = accessToken ? JSON.parse(atob(accessToken.split(".")[1])) : {};
  const { agent_id: agentId, company_name } = decodedToken;
  const { data: activeAgent = [], isLoading } = useGetAgentReferralsQuery(agentId);

  const {
    data: userDetails,
    isLoading: isLoadingDetails,
    error: userDetailsError,
  } = useGetVendorDetailsQuery(selected?.user_id, {
    skip: !selected?.user_id,
  });

  const truncateEmail = (email) => {
    return `${email.slice(0, 5)}...${email.slice(email.length - 5)}`;
  };

  useEffect(() => {
    if (userDetails && !isLoadingDetails) {
      setUserDetailsData(userDetails);
    }
  }, [userDetails, isLoadingDetails]);

  const handleFetchAgentDetails = async (value) => {
    setSelected(value);

    if (!value?.user_id) {
      console.error("Cannot fetch agent details: user_id is missing");
      return null;
    }

    dispatch(setShowAgent(true));
    return userDetails;
  };

  const vendorDataFiltered = activeAgent.filter((value) => {
    const searchTerm = agentSearch?.toLowerCase() || "";
    const location = value.location?.toLowerCase() || "";
    const service = value.service_category?.toLowerCase() || "";
    const phone = value.phoneNumber?.toLowerCase() || "";
    const email = value.email?.toLowerCase() || "";
    const gender = value.gender?.toLowerCase() || "";
    const userId = String(value.user_id || "");
    const date = value.join_date?.toLowerCase() || "";
    const status = value.status?.toLowerCase() || "";

    if (filter) {
      if (filter === "All") return true;
      if (filter === "complete") return value.status === "complete";
      if (filter === "incomplete") return value.status === "incomplete";
    }

    if (!searchTerm) return true;

    return (
      location.includes(searchTerm) ||
      service.includes(searchTerm) ||
      phone.includes(searchTerm) ||
      email.includes(searchTerm) ||
      gender.includes(searchTerm) ||
      userId.includes(searchTerm) ||
      date.includes(searchTerm) ||
      status.includes(searchTerm)
    );
  });

  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  const itemsPerPage = selectedEntry;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = vendorDataFiltered.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(vendorDataFiltered.length / itemsPerPage);

  // const handleVendorId = (id) => {
  //   const vendor = activeAgent.find((agent) => agent.user_id === id);
  //   console.log(vendor);
  //   if (vendor) {
  //     setSelected(vendor);
  //     dispatch(setSelectedAgentId(id));
  //     dispatch(setShowAgent(true));
  //   }
  // };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <Layout>
      {!showAgent && (
        <div className="mx-auto flex w-full flex-col rounded-[10px] bg-primary py-4">
          <div className="mb-10 w-full rounded-[10px] rounded-bl-none rounded-br-none bg-primary shadow-lg">
            <div className="flex w-full items-center justify-between px-12 py-4">
              <div className="text-[25px] font-medium text-secondary capitalize">{company_name} Agency</div>
            </div>
          </div>
          <AgentNavbar />
          <div className="grid w-full grid-cols-9 bg-[#E8E8E8] px-6 py-4 text-sm font-medium text-[#181818]">
            <span>User ID</span>
            <span>Name</span>
            <span>Phone Number</span>
            <span>Email</span>
            <span>Gender</span>
            <span>Location</span>
            <span>Service Category</span>
            <span>Join Date</span>
            <span>Status</span>
          </div>
          {isLoading ? (
            <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
              Loading...
            </div>
          ) : (
            <>
              {currentData.length > 0 ? (
                currentData.map((value) => (
                  <div
                    key={value.id}
                    className="transition-colors ease-in-out hover:cursor-pointer hover:bg-[#eceaea]"
                    // onClick={() => handleVendorId(value.user_id)}
                  >
                    <div className="grid w-full grid-cols-9 px-6 py-4 capitalize">
                      <span>{value.user_id || "N/A"}</span>
                      <span>{value.name || "N/A"}</span>
                      <span>{value.phoneNumber || "N/A"}</span>
                      <span>{truncateEmail(value.email)}</span>
                      <span>{value.gender || "N/A"}</span>
                      <span>{value.location || "N/A"}</span>
                      <span className=" ml-10">{value.service_category || "N/A"}</span>
                      <span>{formatDateSuffix(value.join_date) || "N/A"}</span>
                      <span
                        className={`${value.status === "complete" ? "text-[#1de73f]" : "text-[#ec2727]"}`}
                      >
                        {value.status || "N/A"}
                      </span>
                    </div>
                    <hr color="#AEAEAE" className="w-full" />
                  </div>
                ))
              ) : (
                <div className="flex w-full items-center justify-center pt-4 text-xl text-secondary">
                  No referrals available
                </div>
              )}
            </>
          )}
          <div className="flex w-full justify-between px-6 pt-3">
            <div className="flex w-full">
              <div className="relative flex items-center gap-x-1">
                <span>Showing</span>
                <div
                  onClick={handleSelect}
                  className="flex w-fit items-center gap-x-4 px-5 text-base font-normal text-[#181818] hover:cursor-pointer"
                >
                  <span>{selectedEntry}</span>
                  <img
                    src={Dropdown}
                    alt="open"
                    className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
                  />
                </div>
                {openSelect && (
                  <div className="absolute bottom-[100%] left-[40%] w-[30%] animate-slide_up rounded-[10px] bg-primary px-3 py-2">
                    <div className="flex flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                      {entries.map((entry) => (
                        <span
                          key={entry.id}
                          className="hover:cursor-pointer"
                          onClick={() => {
                            setSelectedEntry(entry.entry);
                            setOpenSelect(false);
                          }}
                        >
                          {entry.entry}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <span>entries</span>
              </div>
            </div>
            <div>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={paginate}
              />
            </div>
          </div>
        </div>
      )}

      {/* {showAgent && selected && (
        <AgentTableDetails
          selectedValue={selected}
          company="John's Agency"
          userInfo={userDetailsData}
          handleFetchAgentDetails={handleFetchAgentDetails}
        />
      )} */}
    </Layout>
  );
};

export default ContractorAgents;
