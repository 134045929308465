import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Services } from "../../../../constants";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

const CategoriesSection = () => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.on("slideChange", () => {
        setIsBeginning(swiperRef.current.isBeginning);
        setIsEnd(swiperRef.current.isEnd);
      });
    }
  }, []);

  return (
    <div className="mx-auto w-full px-7 py-12 sm:px-6 md:py-16 lg:px-8 xl:px-16 2xl:px-40 2xl:py-40">
      <div className="flex w-full flex-col gap-5 md:flex-col md:items-start md:justify-between">
        <div className="flex flex-col gap-y-3 md:w-1/2">
          <div className="flex items-end">
            <hr color="#5f5f5f" className="w-16" />
            <span className="ml-2 text-sm font-normal capitalize text-lightgray sm:text-base lg:text-lg">
              Categories
            </span>
          </div>
          <span className="text-2xl font-semibold text-secondary sm:text-3xl lg:text-4xl xl:text-5xl">
            Enjoy a diverse selection of Vendors
          </span>
        </div>
        <div className="text-sm font-normal text-lightgray md:w-1/2 lg:text-base xl:text-lg">
          Your all-in-one platform to discover reliable vendors at your convenience that cater to
          your every need whether online or physically.
        </div>
      </div>

      <div className="relative mt-7 w-full">
        <Swiper
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={6}
          slidesPerGroup={5}
          speed={800}
          breakpoints={{
            320: { slidesPerView: 2, slidesPerGroup: 2 },
            640: { slidesPerView: 3, slidesPerGroup: 3 },
            1024: { slidesPerView: 5, slidesPerGroup: 5 },
            1280: { slidesPerView: 6, slidesPerGroup: 5 },
          }}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            setIsBeginning(swiper.isBeginning);
            setIsEnd(swiper.isEnd);
          }}
          className="py-5"
        >
          {Services.map((service) => (
            <SwiperSlide key={service.id} className="flex justify-center">
              <div className="group flex h-[215px] w-[180px] flex-col items-center justify-start rounded-2xl border bg-[#0096C1] px-5 py-4 transition-all duration-300 ease-in hover:cursor-pointer hover:bg-[#068fb1] hover:shadow-lg hover:backdrop-blur-md">
                <span className="mb-auto py-2 text-center text-sm font-semibold text-primary sm:text-base">
                  {service.title}
                </span>
                <div className="flex h-[80px] w-full items-center justify-center rounded bg-white px-3 py-4 transition-all duration-300 hover:from-[#5AC8E1] hover:to-[#007A99]">
                  <img
                    src={service.image}
                    alt={service.title}
                    className=" w-12 object-contain sm:h-20 sm:w-20"
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {!isBeginning && (
          <button
            onClick={() => swiperRef.current.slidePrev()}
            className="absolute left-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-800 p-2 text-white shadow-lg transition hover:bg-gray-700"
          >
            <ChevronLeft size={24} />
          </button>
        )}
        {!isEnd && (
          <button
            onClick={() => swiperRef.current.slideNext()}
            className="absolute right-0 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-800 p-2 text-white shadow-lg transition hover:bg-gray-700"
          >
            <ChevronRight size={24} />
          </button>
        )}
      </div>

      <div className="mt-8 flex items-center justify-center md:justify-end">
        <Link to="/services">
          <Button backgroundColor="transparent" textColor="#0096C1" width="fit">
            Explore all services
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default CategoriesSection;
