import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAgentId, setShowEditAgent } from "../../../../../../redux/slice/modalOverlay";
import { useGetAllAgentsQuery, useDeleteAgentMutation } from "../../_api";
import { newIcon } from "../../../../../../constants/images";

const AgentTable = () => {
  const accessToken = useSelector((state) => state.token.states.accessToken);
  const userType = useSelector((state) => state.token.states.userType);
  const { data: agents, error, isLoading } = useGetAllAgentsQuery();
  const [deleteAgent, { isLoading: isDeleting }] = useDeleteAgentMutation();

  const dispatch = useDispatch();
  const [showAction, setShowAction] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);

  const [referralData, setReferralData] = useState({});
  const [referralChanges, setReferralChanges] = useState({});
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const handleEditAgent = (agent) => {
    dispatch(setShowEditAgent(true));
    dispatch(setAgentId(agent));

    clearReferralChangeForAgent(agent.id);
  };

  const handleAction = (agentId) => {
    setSelectedAgentId(agentId === selectedAgentId ? null : agentId);
    setShowAction(!showAction || agentId !== selectedAgentId);
  };

  const clearReferralChangeForAgent = (agentId) => {
    if (referralChanges[agentId]) {
      const updatedChanges = { ...referralChanges };
      delete updatedChanges[agentId];
      setReferralChanges(updatedChanges);
    }
  };

  const handleDeleteClick = (agentId) => {
    setAgentToDelete(agentId);
    setShowDeleteModal(true);
    setShowAction(false);
    clearReferralChangeForAgent(agentId);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setAgentToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteAgent(agentToDelete).unwrap();
      setShowDeleteModal(false);
      setShowAction(false);
    } catch (error) {}
  };

  const fetchAgentReferrals = async (agentId) => {
    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/referrals/${agentId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch referrals for agent ${agentId}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      return null;
    }
  };

  const updateAllAgentsReferrals = async () => {
    if (!agents || agents.length === 0) return;

    const newReferralData = { ...referralData };
    const newReferralChanges = { ...referralChanges };

    for (const agent of agents) {
      const agentId = agent.id;
      const referrals = await fetchAgentReferrals(agentId);

      if (referrals) {
        const currentCount = referrals.length;

        if (initialDataLoaded && referralData[agentId]) {
          const previousCount = referralData[agentId].length;

          if (currentCount !== previousCount) {
            newReferralChanges[agentId] = {
              previous: previousCount,
              current: currentCount,
              difference: currentCount - previousCount,
              timestamp: new Date().toISOString(),
            };
          }
        }

        newReferralData[agentId] = referrals;
      }
    }

    setReferralData(newReferralData);

    if (initialDataLoaded) {
      setReferralChanges(newReferralChanges);
    } else {
      setInitialDataLoaded(true);
    }
  };

  useEffect(() => {
    if (agents && agents.length > 0) {
      updateAllAgentsReferrals();

      const intervalId = setInterval(() => {
        updateAllAgentsReferrals();
      }, 300000);

      return () => clearInterval(intervalId);
    }
  }, [agents]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showAction &&
        !event.target.closest(".action-menu") &&
        !event.target.closest(".action-trigger")
      ) {
        setShowAction(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAction]);

  if (isLoading) {
    return (
      <div className="flex w-full justify-center py-8">
        <span className="text-lg text-secondary">Loading agents...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex w-full justify-center py-8">
        <span className="text-lg text-red-500">Error loading agents. Please try again later.</span>
      </div>
    );
  }

  const allAgents = agents || [];
 
  return (
    <div className="flex w-full flex-col">
      {allAgents.length === 0 && (
        <span className="mx-auto text-center text-secondary lg:text-lg">No agents yet.</span>
      )}
      {allAgents.length !== 0 && (
        <div className="grid w-full grid-cols-4 justify-items-center bg-[#E8E8E8] px-6 py-4 text-lg font-medium text-secondary">
          <span>Name</span>
          <span>Company name</span>
          <span>Number of referrals</span>
          <span>Action</span>
        </div>
      )}
      {allAgents.length !== 0 &&
        allAgents.map((value) => (
          <div className="relative" key={value.id}>
            <div className="relative cursor-pointer transition-colors ease-in-out hover:bg-gray-50">
              <div className="grid w-full grid-cols-4 justify-items-center px-6 py-4">
                <span className="">{value?.agent_name}</span>
                <span className="">{value?.company_name}</span>
                <span className="">
                  {referralData[value.id]?.length !== undefined
                    ? referralData[value.id].length
                    : value?.number_of_referrals}

                  {referralChanges[value.id] && (
                    <span className="ml-2">
                      <span
                        className={
                          referralChanges[value.id].difference > 0
                            ? "text-green-600"
                            : "text-red-600"
                        }
                      >
                        {referralChanges[value.id].difference > 0 ? "+" : ""}
                        {referralChanges[value.id].difference}
                      </span>
                    </span>
                  )}

                  {referralChanges[value.id] && referralChanges[value.id].difference > 0 && (
                    <img
                      src={newIcon}
                      className="absolute right-[22rem] top-3 w-10"
                      alt="New referrals"
                    />
                  )}
                </span>
                <span
                  className="action-trigger cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAction(value.id);
                  }}
                >
                  ...
                </span>
              </div>
              <hr color="#AEAEAE" className="w-full" />
            </div>
            {showAction && selectedAgentId === value.id && (
              <div className="action-menu absolute right-10 top-0 z-10 rounded-[5px] bg-primary py-1 shadow-md">
                <div className="flex w-full flex-col justify-start">
                  <span
                    className="w-full px-10 py-2 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                    onClick={() => handleEditAgent(value)}
                  >
                    View
                  </span>
                  {userType === "Super Admin" && (
                    <span
                      className="w-full px-10 py-2 text-secondary hover:cursor-pointer hover:bg-[#eee]"
                      onClick={() => handleDeleteClick(value.id)}
                    >
                      Delete
                    </span>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}

      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-96 rounded-lg bg-white p-6 shadow-xl">
            <h3 className="mb-4 text-xl font-bold text-secondary">Confirm Delete</h3>
            <p className="mb-6 text-secondary">
              Are you sure you want to delete this agent? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                className="rounded-md bg-gray-200 px-4 py-2 text-secondary hover:bg-gray-300"
                onClick={handleCancelDelete}
                disabled={isDeleting}
              >
                Cancel
              </button>
              <button
                className="rounded-md bg-red-600 px-4 py-2 text-white hover:bg-red-700"
                onClick={handleConfirmDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AgentTable;
