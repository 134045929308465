import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { formSlice } from "../features/formSlice";
import { categorySlice } from "../features/categorySlice";
import { searchSlice } from "../features/searchSlice";
import { overlaySlice } from "../features/overlay";
import { modalOverlaySlice } from "../admin/redux/slice/modalOverlay";
import { tokenSlice } from "../admin/redux/slice/login";
import { userSlice } from "../features/consumerSlice";
import { apiSlice } from "../admin/pages/business/_components/agents/_api";
const persistToken = {
  key: "token",
  storage,
};

export const store = configureStore({
  reducer: {
    formdata: formSlice.reducer,
    category: categorySlice.reducer,
    search: searchSlice.reducer,
    overlay: overlaySlice.reducer,
    modalOverlay: modalOverlaySlice.reducer,
    token: persistReducer(persistToken, tokenSlice.reducer),
    user: userSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

export const persistor = persistStore(store);
