import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SuccessModalDispute from "../../../sucessModal";
import Checkbox from "./ChexBoxes";
import GivePartialToVendorAndConsumer from "./givePartialAward";
import { Spinner } from "../../../../../../constants/images";
import PropTypes from "prop-types";

const ResolutionDecision = ({ reviewData = {} }) => {
  const [active, setActive] = useState(0);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resolutionDecision, setResolutionDecision] = useState({
    disputeId: reviewData?.dispute_id || "",
    decision: "",
    percentageToConsumer: "",
    percentageToVendor: "",
    adminResponse: "",
  });
  const [message, setMessage] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    setResolutionDecision((prev) => ({
      ...prev,
      disputeId: reviewData?.dispute_id || "",
    }));
  }, [reviewData]);

  const handleActive = (value) => {
    setActive(value);
    switch (value) {
      case 1:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "consumer",
          percentageToConsumer: 100,
          percentageToVendor: "",
        }));
        setMessage("Fully awarded to consumer");
        break;
      case 2:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "vendor",
          percentageToConsumer: "",
          percentageToVendor: 100,
        }));
        setMessage("Fully awarded to vendor");
        break;
      case 3:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "partial",
          percentageToConsumer: "",
          percentageToVendor: "",
        }));
        setMessage("");
        break;
      default:
        setResolutionDecision((prev) => ({
          ...prev,
          decision: "",
          percentageToConsumer: "",
          percentageToVendor: "",
        }));
        setMessage("");
    }
  };

  useEffect(() => {
    if (reviewData?.date_of_complain) {
      const dateOfComplain = new Date(reviewData.date_of_complain);
      const now = new Date();
      const timeDifference = now - dateOfComplain;
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      setIsButtonEnabled(hoursDifference > 24 && reviewData.vendor_response === null);
    } else {
      setIsButtonEnabled(false);
    }
  }, [reviewData?.date_of_complain, reviewData?.vendor_response]);

  const handlePartialChange = (e) => {
    const { name, value } = e.target;
    setResolutionDecision((prev) => ({
      ...prev,
      [name]: isNaN(parseInt(value, 10)) ? "" : parseInt(value, 10),
    }));
  };

  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const fetchResolutionDecision = async (e) => {
    e.preventDefault();

    const { percentageToConsumer, percentageToVendor } = resolutionDecision;

    if (
      percentageToConsumer + percentageToVendor !== 100 &&
      resolutionDecision.decision === "partial"
    ) {
      alert("The sum of percentages for Consumer and Vendor must equal 100%.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/handle-disputes`, {
        method: "POST",
        body: JSON.stringify(resolutionDecision),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.clone().json();
          throw new Error(errorData.message || "Unknown error occurred");
        } catch (jsonError) {
          const text = await response.clone().text();
          alert(text);
          console.error("Response text:", text);
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        }
      }

      await response.json();
      setMessage("Decision submitted successfully");
      setShowSuccessModal(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="my-7 rounded-[17px] bg-white px-5 py-8 drop-shadow-md">
      <h3 className="text-[20px] font-medium text-[#002D3A]">Resolution Decision</h3>
      <form action="">
        <div className="mt-4 flex gap-10">
          <Checkbox handleActive={handleActive} active={active} />
        </div>
        <div className="">
          {active === 3 && (
            <GivePartialToVendorAndConsumer
              percentageToConsumer={resolutionDecision.percentageToConsumer}
              handlePartialChange={handlePartialChange}
              percentageToVendor={resolutionDecision.percentageToVendor}
            />
          )}
        </div>
        <h3 className="mt-7 text-[20px]">Reason for resolution (optional)</h3>
        <textarea
          name="reason"
          value={resolutionDecision.adminResponse}
          id=""
          onChange={(e) =>
            setResolutionDecision((prev) => ({ ...prev, adminResponse: e.target.value }))
          }
          cols="70"
          rows="5"
          className="mt-1 rounded-[5px] border-[0.5px] border-[#181818] p-3 text-lightgray outline-none"
        ></textarea>
        <div className="mt-16">
          <button
            className={`w-[35%] rounded-[5px] ${
              !isButtonEnabled || reviewData?.status === "resolving"
                ? "bg-[#0096C1]"
                : "bg-[#b8eaf8]"
            } flex items-center justify-center px-4 py-2 text-[18px] text-white`}
            onClick={fetchResolutionDecision}
            type="submit"
            disabled={isButtonEnabled}
          >
            {isLoading ? (
              <img src={Spinner} alt="Loading..." className="h-5 w-5 animate-spin" />
            ) : (
              "Send"
            )}
          </button>
        </div>
      </form>
      {showSuccessModal && (
        <SuccessModalDispute message={message} adminResponse={resolutionDecision.adminResponse} />
      )}
    </div>
  );
};

ResolutionDecision.propTypes = {
  reviewData: PropTypes.object,
};
export default ResolutionDecision;
