import React from "react";
import mixpanel from "../../..//mixpanel/mixpanel"; 
import {
  whitelogo,
  appstore,
  playstore,
  twitterIcon,
  instaIcon,
  facebookIcon,
  linkedinIcon,
} from "../../../constants/images";
import { Link } from "react-router-dom";

const Footer = () => {
  const trackAppleClick = () => {
    mixpanel.track("Apple Button Clicked", {
      location: "Footer",
      platform: "iOS",
    });
  };

  const trackGoogleClick = () => {
    mixpanel.track("Google Button Clicked", {
      location: "Footer",
      platform: "Android",
    });
  };

  return (
    <div className="mt-20 bg-[#001E27] text-[#FFFFFF]">
      <div className="mx-10 flex flex-col gap-y-10 pb-10 pt-20 md:mx-10 md:flex md:flex-row lg:mx-36 lg:flex lg:flex-row ">
        <div className="flex flex-col gap-y-10 md:w-[38%] lg:w-[40%]">
          <img src={whitelogo} alt="Gywde Logo" className="w-32" />
          <p className="text-[16px] md:w-[65%] lg:w-[65%]">
            At GYWDE, we aim to empower individuals to seamlessly connect with skilled vendors and
            get their work done with confidence.
          </p>
          <div className="hidden md:flex md:gap-2 lg:flex lg:gap-4">
            <Link to="https://x.com/gywdeofficial" target="_blank" className="cursor-pointer">
              <img src={twitterIcon} alt="Twitter" />
            </Link>
            <Link to="https://www.instagram.com/gywde/" target="_blank" className="cursor-pointer">
              <img src={instaIcon} alt="Instagram" />
            </Link>
            <Link
              to="https://www.facebook.com/profile.php?id=61572925026709&mibextid=ZbWKwL"
              target="_blank"
              className="cursor-pointer"
            >
              <img src={facebookIcon} alt="Facebook" />
            </Link>
            <Link
              to="https://www.linkedin.com/company/gywde"
              target="_blank"
              className="cursor-pointer"
            >
              <img src={linkedinIcon} alt="LinkedIn" />
            </Link>
          </div>
          <div className="hidden md:flex md:gap-2 lg:flex lg:gap-4">
            <Link
              to="https://apps.apple.com/gb/app/gywde/id6602887701"
              target="_blank"
              className="cursor-pointer"
              onClick={trackAppleClick}
            >
              <img src={appstore} alt="Apple App Store" className="md:w-[6rem] lg:w-[9rem]" />
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=com.gywd.animation"
              target="_blank"
              className="cursor-pointer"
              onClick={trackGoogleClick}
            >
              <img src={playstore} alt="Google Play Store" className="md:w-[6rem] lg:w-[9rem]" />
            </Link>
          </div>
        </div>
        <div className="grid w-[60%] grid-cols-1 gap-5 md:grid md:grid-cols-3 lg:grid lg:grid-cols-3">
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Categories</p>
            <Link to="/coming-soon" className="group w-fit">
              Repairs and maintenance
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Digital services
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Events services
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Beauty services
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Transport and logistics
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              Consulting services
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Company</p>
            <Link to="/about-us" className="group w-fit">
              About Us
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="#" className="group w-fit">
              Careers
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/coming-soon" className="group w-fit">
              FAQ
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/privacy-policy.pdf" target="_blank" className="group w-fit">
              Privacy policy
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/consumer-terms.pdf" target="_blank" className="group w-fit">
              Terms of Service for Consumers
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
            <Link to="/vendor-terms.pdf" target="_blank" className="group w-fit">
              Terms of Service for Vendors
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
          <div className="flex flex-col gap-y-5">
            <p className="font-semibold">Contact Us</p>
            <Link to="mailto:support@gywde.com" target="_blank" className="group w-fit">
              support@gywde.com{" "}
              <div className="h-[2px] w-0 bg-primary transition-all duration-500 group-hover:w-full"></div>
            </Link>
          </div>
        </div>
        <div className="flex gap-2 md:hidden lg:hidden">
          <Link to="https://x.com/gywdeofficial" target="_blank" className="cursor-pointer">
            <img src={twitterIcon} alt="Twitter" />
          </Link>
          <Link to="https://www.instagram.com/gywde/" target="_blank" className="cursor-pointer">
            <img src={instaIcon} alt="Instagram" />
          </Link>
          <Link
            to="https://www.facebook.com/profile.php?id=61572925026709&mibextid=ZbWKwL"
            target="_blank"
            className="cursor-pointer"
          >
            <img src={facebookIcon} alt="Facebook" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/gywde"
            target="_blank"
            className="cursor-pointer"
          >
            <img src={linkedinIcon} alt="LinkedIn" />
          </Link>
        </div>
        <div className="flex gap-2 md:hidden lg:hidden">
          <Link
            to="https://apps.apple.com/gb/app/gywde/id6602887701"
            target="_blank"
            className="cursor-pointer"
            onClick={trackAppleClick}
          >
            <img src={appstore} alt="Apple App Store" className="md:w-[6rem] lg:w-[7rem]" />
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.gywd.animation"
            target="_blank"
            className="cursor-pointer"
            onClick={trackGoogleClick}
          >
            <img src={playstore} alt="Google Play Store" className="md:w-[6rem] lg:w-[7rem]" />
          </Link>
        </div>
      </div>

      <div className="w-full border-t-4 border-[#002D3A]"></div>
      <div className="mx-10 flex justify-start py-8 md:items-center md:justify-center lg:items-center lg:justify-center">
      <p>© Gywde {new Date().getFullYear()}, All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
