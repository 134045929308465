import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { setDiscountDetails, setEditDiscount } from "../../../../../../redux/slice/modalOverlay";
import { Spinner } from "../../../../../../constants/images";

const EditDiscountModal = ({ fetchAllDiscount }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [success, setSuccess] = useState(false);
  const discountId = useSelector((state) => state.modalOverlay.states.discountDetails?.id);
  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const [details, setDetails] = useState({
    name: "",
    percentage: "",
    usage_frequency: "",
    referral_code: "",
    discount_code: "",
    valid_until: "",
  });

  const [selectedImage, setSelectedImage] = useState("");

  const [errorState, setErrorState] = useState({
    nameError: "",
    percentageError: "",
    usage_frequencyError: "",
    valid_untilError: "",
    updateError: "",
  });

  useEffect(() => {
    const fetchDiscountDetails = async () => {
      if (!discountId) return;

      setFetching(true);
      try {
        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/admin/discount/${discountId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const formattedDate = data.valid_until
          ? new Date(data.valid_until).toISOString().split("T")[0]
          : "";

        setDetails({
          name: data.name || "",
          percentage: data.percentage || "",
          usage_frequency: data.usage_frequency || "",
          referral_code: data.referral_code || "N/A",
          discount_code: data.discount_code || "",
          valid_until: formattedDate || "",
        });

        setSelectedImage(data.discount_image_url || "");
        dispatch(setDiscountDetails(data));
      } catch (error) {
      } finally {
        setFetching(false);
      }
    };

    fetchDiscountDetails();
  }, [discountId, accessToken, dispatch]);

  const handleForm = (e) => {
    const { name, value } = e.target;

    if (name === "referral_code") {
      return;
    }

    setDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const validate = () => {
    let isError = false;
    const errors = {
      nameError: "",
      percentageError: "",
      usage_frequencyError: "",
      valid_untilError: "",
    };

    if (!details.name) {
      isError = true;
      errors.nameError = "Please enter a name";
    }

    if (!details.percentage) {
      isError = true;
      errors.percentageError = "Please enter a percentage";
    } else if (isNaN(details.percentage) || details.percentage <= 0 || details.percentage > 100) {
      isError = true;
      errors.percentageError = "Please enter a valid percentage between 1 and 100";
    }

    if (!details.usage_frequency) {
      isError = true;
      errors.usage_frequencyError = "Please enter usage frequency";
    } else if (isNaN(details.usage_frequency) || details.usage_frequency <= 0) {
      isError = true;
      errors.usage_frequencyError = "Please enter a valid usage frequency";
    }

    if (!details.valid_until) {
      isError = true;
      errors.valid_untilError = "Please enter a valid date";
    }

    setErrorState((prev) => ({
      ...prev,
      ...errors,
      updateError: "",
    }));

    return isError;
  };

  const handleClose = () => {
    dispatch(setEditDiscount(false));
    dispatch(setDiscountDetails({}));
  };

  const handleEditDiscount = async () => {
    const error = validate();

    if (!error) {
      setLoading(true);
      try {
        const payload = {
          name: details.name,
          percentage: details.percentage,
          usage_frequency: details.usage_frequency,
          valid_until: details.valid_until,
          discount_code: details.discount_code,
        };

        const response = await fetch(
          `${import.meta.env.VITE_BASE_URL}/admin/discount/${discountId}`,
          {
            method: "PUT",
            body: JSON.stringify(payload),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!response.ok) {
          const text = await response.text();
          setErrorState((prev) => ({
            ...prev,
            updateError: text || "Failed to update discount.",
          }));
          setLoading(false);
          return;
        }

        await response.json();
        setSuccess(true);
        setLoading(false);
        setTimeout(() => {
          fetchAllDiscount();
          setSuccess(false);
          dispatch(setDiscountDetails({}));
          dispatch(setEditDiscount(false));
        }, 3000);
      } catch (error) {
        setErrorState((prev) => ({
          ...prev,
          updateError: error.message || "Failed to update discount.",
        }));
        setLoading(false);
      }
    }
  };

  return (
    <div className="relative h-[80vh] animate-slide_up overflow-y-auto rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-5 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        x
      </div>

      {fetching ? (
        <div className="flex h-full items-center justify-center">
          <span className="text-secondary">Loading...</span>
        </div>
      ) : (
        <div className="flex flex-col gap-y-3 px-8">
          <span className="py-2 text-start text-xl font-semibold text-secondary">
            Edit Discount
          </span>

          <div className="flex flex-col">
            <label htmlFor="name" className="text-base font-normal text-[#181818]">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={details.name}
              onChange={handleForm}
              className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            {errorState.nameError && (
              <span className="text-start text-sm text-red-500">{errorState.nameError}</span>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="percentage" className="text-base font-normal text-[#181818]">
              Percentage
            </label>
            <input
              type="text"
              name="percentage"
              id="percentage"
              value={details.percentage}
              onChange={handleForm}
              className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            {errorState.percentageError && (
              <span className="text-start text-sm text-red-500">{errorState.percentageError}</span>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="usage_frequency" className="text-base font-normal text-[#181818]">
              Usage frequency
            </label>
            <div className="flex items-center gap-x-2">
              <input
                type="text"
                name="usage_frequency"
                id="usage_frequency"
                value={details.usage_frequency}
                onChange={handleForm}
                className="w-1/2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
              />
              <span className="text-base font-normal text-lightgray">time(s)</span>
            </div>
            {errorState.usage_frequencyError && (
              <span className="text-start text-sm text-red-500">
                {errorState.usage_frequencyError}
              </span>
            )}
          </div>

          <div className="flex flex-col">
            <label htmlFor="referral_code" className="text-base font-normal text-[#181818]">
              Referral&apos;s code
            </label>
            <input
              type="text"
              name="referral_code"
              id="referral_code"
              value={details.referral_code}
              onChange={handleForm}
              className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 bg-gray-100 px-2 py-3 outline-none"
              disabled
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="discount_code" className="text-base font-normal text-[#181818]">
              Discount code
            </label>
            <input
              type="text"
              name="discount_code"
              id="discount_code"
              value={details.discount_code}
              placeholder="Set discount code"
              onChange={handleForm}
              className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 bg-gray-100 px-2 py-3 outline-none"
              disabled
            />
          </div>

          <div className="flex flex-col">
            <label htmlFor="valid_until" className="text-base font-normal text-[#181818]">
              Valid until
            </label>
            <input
              type="date"
              name="valid_until"
              id="valid_until"
              value={details.valid_until}
              onChange={handleForm}
              className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            {errorState.valid_untilError && (
              <span className="text-start text-sm text-red-500">{errorState.valid_untilError}</span>
            )}
          </div>

          {selectedImage && (
            <div className="flex flex-col gap-y-2">
              <span className="text-base font-normal text-[#181818]">Image</span>
              <img src={selectedImage} alt="discount" className="w-[30%]" />
            </div>
          )}
          {errorState.updateError && (
            <span className="text-start text-sm text-red-500">{errorState.updateError}</span>
          )}

          <div className="mt-10 w-full">
            <Button
              className="flex w-full items-center justify-center"
              onClick={handleEditDiscount}
              disabled={loading}
            >
              {loading ? <img src={Spinner} alt="loading" className="w-[5%]" /> : "Update Discount"}
            </Button>
          </div>
        </div>
      )}

      {success && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-5 shadow-lg">
            <span className="text-xl font-semibold">Success!</span>
            <p className="mt-2">Discount updated successfully.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditDiscountModal;
