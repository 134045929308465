import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Delete, Edit } from "../../../../../../constants/images";
import { setDiscountDetails, setEditDiscount } from "../../../../../../redux/slice/modalOverlay";
import { formatDate } from "../../../../../../utils/dateUtils";
import ConfirmationModal from "./deleteConfirmation";

const DiscountCard = ({ allDiscount, fetchAllDiscount }) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [showModal, setShowModal] = useState(false);
  const [selectedDiscountId, setSelectedDiscountId] = useState(null); 
  const dispatch = useDispatch();

  const handleEditDiscount = (item) => {
    dispatch(setEditDiscount(true));
    dispatch(setDiscountDetails(item));
  };

  const handleDeleteDiscount = (discountId) => {
    setSelectedDiscountId(discountId);
    setShowModal(true); 
  };

  const deleteDiscount = async () => {
    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/discount/${selectedDiscountId}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      const data = await response.json();

      fetchAllDiscount(); 
    } catch (error) {
    
    } finally {
      setShowModal(false);
    }
  };

  useEffect(() => {
    fetchAllDiscount();
  }, []);

  return (
    <div className="flex w-full flex-col">
      {allDiscount?.length === 0 ? (
        <span className="mx-auto text-secondary lg:text-lg">No discounts available.</span>
      ) : (
        <div className="float-end ml-auto grid w-full grid-cols-5 gap-x-10 pb-14">
          {allDiscount.map((discountItem, index) => (
            <div
              key={index}
              className={`flex h-fit w-full flex-col  rounded-[10px] px-3 py-8 pb-5 shadow-lg transition-all ease-in hover:scale-105 hover:cursor-pointer`}
            >
              <div className="flex w-full justify-between">
                <div className="w-full text-lg font-medium text-secondary">
                  {discountItem?.name}
                </div>
                <div className="flex w-full items-center justify-end gap-x-4">
                  <img src={Edit} alt="edit" onClick={() => handleEditDiscount(discountItem)} />
                  <img
                    src={Delete}
                    alt="delete"
                    onClick={() => handleDeleteDiscount(discountItem?.id)}
                  />
                </div>
              </div>
              <img
                src={discountItem.discount_image_url}
                alt="Discount image"
                className="mt-3 w-full h-[300px] object-cover "
              />
              <div className="flex space-x-1 bg-[#0081A6] px-2 py-1  text-[10px] leading-3 text-[white]">
                <div className="">
                  <span className="block">valid until</span>
                  <span className="">{formatDate(discountItem?.valid_until)}</span>
                </div>
                <div className="">
                  <span className="block">usage</span>
                  <span className="">{discountItem?.usage_frequency}</span>
                </div>
                <div className="">
                  <span className="block">discount code</span>
                  <span className="">{discountItem?.discount_code}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <ConfirmationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={deleteDiscount}
      />
    </div>
  );
};

export default DiscountCard;
