import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownWhite, SearchIcon } from "../../../../../../../../constants/images";
import {
  setAgentSearch,
  setSelectedReferral,
} from "../../../../../../../../redux/slice/modalOverlay";

const AgentNavbar = () => {
  const dispatch = useDispatch();
  const [openSelect, setOpenSelect] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [referralFilter, setReferralFilter] = useState("All");
  const [selectedSort, setSelectedSort] = useState("");
  const handleSelect = () => {
    setOpenSelect(!openSelect);
  };

  const handleSort = () => {
    setOpenSort(!openSort);
  };

  const handleReferralSelect = (e) => {
    setReferralFilter(e);
    dispatch(setSelectedReferral(e));
    setOpenSelect(false);
  };

  const handleChange = (e) => {
    dispatch(setAgentSearch(e.target.value));
  };

  return (
    <div className="mx-auto flex w-full justify-between px-6 py-4">
      <div className="relative flex w-[20%] items-center gap-x-2">
        <span className="font-medium text-secondary md:text-lg">Referral</span>
        <div
          onClick={handleSelect}
          className="flex w-full capitalize items-center justify-between gap-x-4 rounded-lg border border-[#AEAEAE] px-3 py-2 text-base font-normal text-[#181818] hover:cursor-pointer"
        >
          <span>{referralFilter}</span>
          <img
            src={Dropdown}
            alt="open"
            className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
          />
        </div>
        {openSelect && (
          <div className="absolute top-12 w-full animate-slide_up rounded-[10px]  bg-primary px-3 py-4 drop-shadow-lg">
            <div className="flex flex-col items-center gap-y-2 text-base font-medium capitalize text-[#181818]">
              <span
                className="hover:cursor-pointer hover:text-secondary"
                onClick={() => handleReferralSelect("All")}
              >
                All
              </span>
              <span
                className="hover:cursor-pointer hover:text-secondary"
                onClick={() => handleReferralSelect("complete")}
              >
                Complete
              </span>
              <span
                className="hover:cursor-pointer hover:text-secondary"
                onClick={() => handleReferralSelect("incomplete")}
              >
                Incomplete
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="flex items-center gap-x-2">
        <div className="relative ml-16 flex w-full items-center justify-center">
          <input
            type="text"
            name="search"
            id="search"
            onChange={handleChange}
            className="w-full rounded-[5px] border border-[#AEAEAE] px-10 py-2 placeholder:text-xs placeholder:font-medium placeholder:text-[#AEAEAE] md:placeholder:text-sm "
            placeholder="Search here"
          />{" "}
          <img src={SearchIcon} alt="search" className="absolute left-0 px-2" />
        </div>
        <div className="relative flex w-1/2 items-center justify-between gap-x-4 rounded-[5px] bg-lightblue px-5 py-2 text-sm font-medium text-primary hover:cursor-pointer">
          <div className="flex w-full justify-between" onClick={handleSort}>
            <span>Sort by</span>
            <img
              src={DropdownWhite}
              alt="open"
              className={`${openSort && "-rotate-90 transition-all ease-in-out"}`}
            />
          </div>

          {openSort && (
            <div className="absolute left-0 top-[100%] w-[180px] animate-slide_up  rounded-[10px] bg-primary px-2 py-6">
              <div className="flex w-full flex-col items-center gap-y-2 text-base font-normal text-[#181818]">
                <span className="hover:cursor-pointer" onClick={() => setSelectedSort("")}>
                  Newest - Oldest
                </span>
                <span className="hover:cursor-pointer" onClick={() => setSelectedSort("")}>
                  Oldest - Newest
                </span>
                <span className="hover:cursor-pointer" onClick={() => setSelectedSort("")}>
                  Location
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgentNavbar;
