import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  states: {
    modalOverlay: false,
    activeTable: "",
    activeProject: "",
    selectedProject: "",
    projectSearch: "",
    selectedTransaction: "",
    transactionSearch: "",
    consumerSearch: "",
    vendorSearch: "",
    projectId: "",
    vendorId: "",
    consumerId: "",
    disputeId: "",
    showDispute: false,
    showVendor: false,
    showConsumer: false,
    showProject: false,
    showResponse: false,
    editAdvert: false,
    deleteAdvert: false,
    deleteDiscount: false,
    addNewAdvert: false,
    advertDetails: [],
    newAdvert: [],
    agentDetails: [],
    advertId: "",
    editDiscount: false,
    addNewDiscount: false,
    discountDetails: {},
    discountId: "",
    vat: "",
    commission: "",
    addNewAgent: false,
    agentId: "",
    showEditAgent: "",
    showAgent: false,
    agentSearch: "",
    selectedAgentId: "",
    selectedReferral: "",
    showBannedAccounts: false,
    showAgentModal: false,
    showAdvertModal: false,
    showDiscountModal: false,
    showPermissionsModal: false,
    showAddPermission: false,
    refreshAd: false,
    permissionDetails: {},
    refreshPermission: false,
  },
};
export const modalOverlaySlice = createSlice({
  name: "modalOverlay",
  initialState: initialState,
  reducers: {
    setModalOverlay: (state, action) => {
      state.states.modalOverlay = action.payload;
    },
    setActiveTable: (state, action) => {
      state.states.activeTable = action.payload;
    },
    setActiveProject: (state, action) => {
      state.states.activeProject = action.payload;
    },
    setSelectedProject: (state, action) => {
      state.states.selectedProject = action.payload;
    },
    setSelectedTransaction: (state, action) => {
      state.states.selectedTransaction = action.payload;
    },
    setTransactionSearch: (state, action) => {
      state.states.transactionSearch = action.payload;
    },
    setProjectSearch: (state, action) => {
      state.states.projectSearch = action.payload;
    },
    setConsumerSearch: (state, action) => {
      state.states.consumerSearch = action.payload;
    },
    setVendorSearch: (state, action) => {
      state.states.vendorSearch = action.payload;
    },
    setProjectId: (state, action) => {
      state.states.projectId = action.payload;
    },
    setVendorId: (state, action) => {
      state.states.vendorId = action.payload;
    },
    setConsumerId: (state, action) => {
      state.states.consumerId = action.payload;
    },
    setDisputeId: (state, action) => {
      state.states.disputeId = action.payload;
    },
    setShowDispute: (state, action) => {
      state.states.showDispute = action.payload;
    },
    setShowVendor: (state, action) => {
      state.states.showVendor = action.payload;
    },
    setShowConsumer: (state, action) => {
      state.states.showConsumer = action.payload;
    },
    setShowProject: (state, action) => {
      state.states.showProject = action.payload;
    },
    setShowResponse: (state, action) => {
      state.states.showResponse = action.payload;
    },
    setEditAdvert: (state, action) => {
      state.states.editAdvert = action.payload;
    },
    setDeleteAdvert: (state, action) => {
      state.states.deleteAdvert = action.payload;
    },
    setDeleteDiscount: (state, action) => {
      state.states.deleteDiscount = action.payload;
    },
    setAddNewAdvert: (state, action) => {
      state.states.addNewAdvert = action.payload;
    },
    setAddNewDiscount: (state, action) => {
      state.states.addNewDiscount = action.payload;
    },
    setAdvertDetails: (state, action) => {
      state.states.advertDetails = action.payload;
    },
    setAgentDetails: (state, action) => {
      state.states.agentDetails = action.payload;
    },
    setAdvertId: (state, action) => {
      state.states.advertId = action.payload;
    },
    setEditDiscount: (state, action) => {
      state.states.editDiscount = action.payload;
    },
    setDiscountDetails: (state, action) => {
      state.states.discountDetails = action.payload;
    },
    setDiscountId: (state, action) => {
      state.states.discountId = action.payload;
    },
    setVat: (state, action) => {
      state.states.vat = action.payload;
    },
    setCommission: (state, action) => {
      state.states.commission = action.payload;
    },
    setAddNewAgent: (state, action) => {
      state.states.addNewAgent = action.payload;
    },
    setAgentId: (state, action) => {
      state.states.agentId = action.payload;
    },
    setShowEditAgent: (state, action) => {
      state.states.showEditAgent = action.payload;
    },
    setShowAgent: (state, action) => {
      state.states.showAgent = action.payload;
    },
    setSelectedAgentId: (state, action) => {
      state.states.selectedAgentId = action.payload;
    },
    setAgentSearch: (state, action) => {
      state.states.agentSearch = action.payload;
    },
    setSelectedReferral: (state, action) => {
      state.states.selectedReferral = action.payload;
    },
    setShowBannedAccounts: (state, action) => {
      state.states.showBannedAccounts = action.payload;
    },
    setShowAgentModal: (state, action) => {
      state.states.showAgentModal = action.payload;
    },
    setShowAdvertModal: (state, action) => {
      state.states.showAdvertModal = action.payload;
    },
    setShowDiscountModal: (state, action) => {
      state.states.showDiscountModal = action.payload;
    },
    setNewAdvert: (state, action) => {
      state.states.newAdvert = action.payload;
    },
    setRefreshAd: (state, action) => {
      state.states.refreshAd = action.payload;
    },
    setShowPermisionsModal: (state, action) => {
      state.states.showPermissionsModal = action.payload;
    },
    setShowAddPermission: (state, action) => {
      state.states.showAddPermission = action.payload;
    },
    setPermissionDetails: (state, action) => {
      state.states.permissionDetails = action.payload;
    },
    setRefreshPermission: (state, action) => {
      state.states.refreshPermission = action.payload;
    },
  },
});

export const {
  setModalOverlay,
  setProjectSearch,
  setTransactionSearch,
  setActiveTable,
  setActiveProject,
  setSelectedProject,
  setConsumerSearch,
  setVendorSearch,
  setSelectedTransaction,
  setProjectId,
  setShowVendor,
  setVendorId,
  setShowConsumer,
  setConsumerId,
  setShowProject,
  setDisputeId,
  setShowDispute,
  setShowResponse,
  setEditAdvert,
  setDeleteAdvert,
  setAddNewAdvert,
  setAdvertDetails,
  setAdvertId,
  setAddNewDiscount,
  setEditDiscount,
  setDeleteDiscount,
  setDiscountDetails,
  setDiscountId,
  setVat,
  setCommission,
  setAddNewAgent,
  setAgentDetails,
  setAgentId,
  setShowEditAgent,
  setSelectedAgentId,
  setShowAgent,
  setAgentSearch,
  setSelectedReferral,
  setShowBannedAccounts,
  setShowAgentModal,
  setRefreshAd,
  setShowAdvertModal,
  setShowDiscountModal,
  setNewAdvert,
  setShowPermisionsModal,
  setShowAddPermission,
  setPermissionDetails,
  setRefreshPermission,
} = modalOverlaySlice.actions;
