import { useEffect } from "react";
import { motion } from "framer-motion";
import {
  ApartSection,
  BannerSection,
  CommitmentSection,
  ContactSection,
  FAQSSection,
  HeroSection,
  MissionSection,
} from "./_components";
import { Banner, Footer, Navbar } from "../../components/ui";
import { useLocation } from "react-router-dom";
import { fadeIn, slideInLeft, slideInRight } from "../home/utils/motionVarian";
const AboutUs = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);

  return (
    <div style={{ overflowX: "hidden" }}>
      <Navbar />
      <motion.div initial="hidden" animate="visible" variants={fadeIn}>
        <HeroSection />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.2 }}
        variants={slideInLeft}
        style={{ position: "relative" }}
      >
        <MissionSection />
      </motion.div>

      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={slideInRight}
        style={{ position: "relative" }}
      >
        <CommitmentSection />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={fadeIn}
      >
        <ApartSection />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={fadeIn}
      >
        <BannerSection />
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={fadeIn}
      >
        <div className="flex flex-col gap-10 py-20 lg:flex-row">
          <FAQSSection />
          <ContactSection />
        </div>
      </motion.div>
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false }}
        variants={fadeIn}
      >
        <Banner />
      </motion.div>
      <Footer />
    </div>
  );
};

export default AboutUs;
