import React, { useState } from "react";
import { Button } from "../../../../../../../../components";

const VerificationModal = ({ onClick, handleConfirmReject, value, details }) => {
  const labelStyle = `text-sm font-normal mr-2 text-lightgray`;
  const idContent = `text-base font-medium text-secondary`;
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async (action) => {
    setIsLoading(true);
    await handleConfirmReject(action);
    setIsLoading(false);
  };

  return (
    <div className="relative w-[60%] rounded-xl bg-primary py-5 h-[400px] overflow-y-auto">
      <div className="absolute right-10 text-xl font-medium hover:cursor-pointer" onClick={onClick}>
        x
      </div>
      <div className="flex flex-col items-center justify-between h-full gap-y-3 pt-10">
        <span className="text-lg font-semibold text-secondary">Verify Work ID</span>
        {details?.workid_url ? (
          <img src={details?.workid_url} className="w-[200px]" alt="id_card" />
        ) : (
          <span className="text-lg font-normal text-[#181818]">No work ID uploaded</span>
        )}
        <div className="flex gap-x-2">
          <div>
            <span className={labelStyle}>Name:</span>
            <span className={idContent}>{value.name}</span>
          </div>
          <div>
            <span className={labelStyle}>Category:</span>
            <span className={idContent}>{value.service_category ?? "N/A"}</span>
          </div>
          <div>
            <span className={labelStyle}>Specialty:</span>
            <span className={idContent}>{details?.specialty ?? "N/A"}</span>
          </div>
        </div>
        <div className="text-lg font-normal text-[#181818]">
          Does the information match the work ID?
        </div>
        {!isLoading && (
          <div className="flex gap-x-4">
            <Button
              backgroundColor="transparent"
              textColor="#0096C1"
              onClick={() => handleClick("reject")}
              disabled={isLoading}
            >
              No
            </Button>
            <Button onClick={() => handleClick("confirm")} disabled={isLoading}>
              Yes
            </Button>
          </div>
        )}
        {isLoading && <Button>Loading...</Button>}
      </div>
    </div>
  );
};

export default VerificationModal;
