import { motion } from "framer-motion";
import { Banner, Footer, Navbar } from "../../components/ui";
import {
  AboutUsSection,
  CategoriesSection,
  HeroSection,
  HowItWorksSection,
  PostJobSection,
  ServicesSection,
  TestimonialsSection,
} from "./_components";
import { fadeIn, slideInLeft, slideInRight } from "./utils/motionVarian";
const Home = () => {
  return (
    <div className="overflow-x-hidden overflow-y-hidden">
      <Navbar />
      <motion.div initial="hidden" animate="visible" variants={fadeIn}>
        <HeroSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false, amount: 0.2 }} variants={slideInLeft} style={{ position: "relative" }}>
        <AboutUsSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={fadeIn}>
        <div className="mx-auto max-w-[1600px] px-4 lg:px-10">
          <ServicesSection />
        </div>
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={fadeIn}>
        <CategoriesSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={fadeIn}>
        <HowItWorksSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={fadeIn}>
        <PostJobSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={slideInRight} style={{ position: "relative" }}>
        <TestimonialsSection />
      </motion.div>

      <motion.div initial="hidden" whileInView="visible" viewport={{ once: false }} variants={fadeIn}>
        <Banner />
      </motion.div>

      <Footer />
    </div>
  );
};


export default Home;
