import { useState, useEffect } from "react";
import { hamburger, logo } from "../../../constants/images";
import { Link, NavLink } from "react-router-dom";
import { Button } from "../";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        className={`lg:px-13 fixed top-0 z-50 flex h-12 w-full items-center justify-between px-6 drop-shadow-md transition-all duration-300 ease-out md:top-0 md:h-14 md:gap-3 md:px-12 lg:h-20 xl:gap-0 ${
          isScrolled ? "bg-primary/50 backdrop-blur-sm" : "bg-inherit text-black"
        }`}
      >
        <Link to="/">
          <img src={logo} className="h-8 md:h-10 lg:h-12" alt="logo" />
        </Link>
        <div
          className={`hidden items-center justify-center font-normal md:flex md:space-x-10 md:text-xs lg:space-x-12 lg:text-lg ${isScrolled ? "text-black" : "text-white"}`}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "font-bold" : ""} transition-colors duration-300 ease-in-out hover:text-gray-300`
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about-us"
            className={({ isActive }) =>
              `${isActive ? "font-bold" : ""} transition-colors duration-300 ease-in-out hover:text-gray-300`
            }
          >
            About Us
          </NavLink>
          <NavLink
            to="/services"
            className={({ isActive }) =>
              `${isActive ? "font-bold" : ""} transition-colors duration-300 ease-in-out hover:text-gray-300`
            }
          >
            Services
          </NavLink>
          <NavLink
            to="/vendor"
            className={({ isActive }) =>
              `${isActive ? "font-bold" : ""} transition-colors duration-300 ease-in-out hover:text-gray-300`
            }
          >
            Be a Vendor
          </NavLink>
        </div>

        <div className="hidden items-center space-x-5 md:flex lg:space-x-10">
          <Link to="/login">
            <Button
              backgroundColor={"transparent"}
              textColor={isScrolled ? "black" : "white"}
              className={`text-nowrap rounded-[5px] font-semibold transition-colors duration-300 ease-in-out md:!px-[10px] md:!py-[8px] md:text-xs lg:!px-[20px] lg:!py-[10px] xl:text-lg`}
            >
              Log in
            </Button>
          </Link>
          <Link to="/signup">
            <Button
              backgroundColor={"#0096C1"}
              border="none"
              className={`text-nowrap rounded-[5px] font-semibold ease-in-out md:!px-[10px] md:!py-[8px] md:text-xs lg:!px-[20px] lg:!py-[10px] xl:text-lg`}
            >
              Sign up
            </Button>
          </Link>
        </div>

        <div className="block cursor-pointer md:hidden">
          <img src={hamburger} alt="hamburger" onClick={() => setToggleMenu(true)} />
        </div>
      </div>
      {toggleMenu && (
        <div className="animate-slideBottom fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center gap-20 overflow-hidden bg-primary px-6 pt-6 transition-all duration-150">
          <div className="flex w-full items-center justify-between">
            <img src="/logo.png" alt="gywde" className="h-8 md:h-10 lg:h-12" />

            <span
              className="right-6  top-5 cursor-pointer text-2xl"
              onClick={() => setToggleMenu(false)}
            >
              X
            </span>
          </div>
          <ul className="w-full list-none">
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link to="/" className=" text-lg font-normal text-lightgray">
                  Home
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link to="/about-us" className=" text-lg font-normal text-lightgray">
                  About Us
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link to="/services" className=" text-lg font-normal text-lightgray">
                  Services
                </Link>
              </div>
            </li>
            <li className="mt-12 cursor-pointer text-xl text-primary">
              <div className="flex items-center justify-center">
                <Link to="/vendor" className=" text-lg font-normal text-lightgray">
                  Be a Vendor
                </Link>
              </div>
            </li>
          </ul>
          <div className="mt-20 flex w-full flex-col items-center space-y-6 text-base">
            <Link to="/login" className="mx-auto w-[100%]">
              <Button
                className="w-full !text-base font-semibold  !text-lightgray hover:scale-95"
                backgroundColor={"transparent"}
                padding={"15px 30px"}
              >
                Log in
              </Button>
            </Link>
            <Link to="/signup" className="mx-auto w-[100%]">
              <Button
                className="w-full !text-base font-semibold  text-primary hover:scale-95"
                border={"none"}
                padding={"15px 30px"}
              >
                Sign up
              </Button>
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
