import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const accessToken = getState().token.states.accessToken;
      headers.set("Authorization", `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ["Agents", "AgentReferrals", "VendorDetails"],
  endpoints: (builder) => ({
    getAllAgents: builder.query({
      query: () => "/admin/agents",
      providesTags: ["Agents"],
    }),
    deleteAgent: builder.mutation({
      query: (agentId) => ({
        url: "/admin/agent",
        method: "DELETE",
        body: { agentId },
      }),
      invalidatesTags: ["Agents"],
    }),
    addAgent: builder.mutation({
      query: (agentData) => ({
        url: "/admin/add-agent",
        method: "POST",
        body: agentData,
      }),
      invalidatesTags: ["Agents"],
    }),
    getAgentReferrals: builder.query({
      query: (agentId) => `/admin/referrals/${agentId}`,
      providesTags: (result, error, agentId) => [{ type: "AgentReferrals", id: agentId }],
    }),
    getVendorDetails: builder.query({
      query: (userId) => `/admin/referrals/${userId}/vendor-details`,
      providesTags: (result, error, userId) => [{ type: "VendorDetails", id: userId }],
    }),
  }),
});

export const {
  useGetAllAgentsQuery,
  useDeleteAgentMutation,
  useAddAgentMutation,
  useGetAgentReferralsQuery,
  useGetVendorDetailsQuery,
} = apiSlice;
