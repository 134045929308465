import { Experience } from "../../../../constants";
import Card from "./_components/card";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  return (
    <div className="rounded-2xl bg-[#E6F5F9] py-14 lg:px-24">
      <span className="mb-5  flex flex-row  items-end  justify-center gap-0 text-center  text-[18px] text-lg font-[400] leading-tight text-[#5F5F5F] md:flex-row md:items-end md:gap-0 md:text-[20px]">
        <hr className="h-[1px] w-16 border-0 dark:bg-[#5F5F5F] " />
        Why Choose Us?
      </span>
      <h3 className="font-[Work Sans]  mx-auto  mb-9 w-[306px] text-center text-[25px] font-semibold leading-[29.33px] text-[#002D3A] lg:w-[629px] lg:text-[40px] lg:leading-[46.92px]">
        Elevate Your Service Experience
      </h3>
      <div className=" grid  gap-7 gap-x-7 px-7 py-7 md:grid-cols-2 md:px-0 lg:grid-cols-4">
        {Experience.map((cardData, i) => (
          <Card key={i} {...cardData} />
        ))}
      </div>
      <Link to="/coming-soon" className="flex justify-center pt-2">
        <Button border="none"> Get Started</Button>
      </Link>
    </div>
  );
};

export default ServicesSection;
