const Card = ({ image, title, paragraph }) => {
  return (
    <div className="mx-auto flex w-full max-w-[300px] flex-col items-center justify-center space-y-5 rounded-[10px] bg-[#FFFFFF] p-[2.1rem]">
      <img src={image} alt="image" className="" />
      <h3 className="font-[Work Sans] text-[20px] font-semibold leading-[23.46px] text-[#002D3A]">
        {title}
      </h3>
      <p className="min-w-[230px] gap-[10px] text-center text-[18px] font-[400px] leading-[18.77px] text-[#5F5F5F] md:text-[16px]">
        {paragraph}
      </p>
    </div>
  );
};

export default Card;
