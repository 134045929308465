import React from "react";
import Card from "./_components/card";

const TestimonialsSection = () => {
  return (
    <div className="p-8 md:px-12 lg:px-36 lg:py-20">
      <div className="flex flex-col items-start justify-center md:items-center">
        <div className="py-10">
          <div className="items-end md:flex ">
            <hr color="#5f5f5f" className="w-[15%]" />
            <span className="text-sm font-normal capitalize text-lightgray md:text-base lg:text-lg">
              Testimonials
            </span>
          </div>
          <span className="text-[25px] font-semibold text-secondary md:text-[30px] lg:text-[40px]">
            Here is what people are saying about us
          </span>
        </div>
      </div>
      <Card />
    </div>
  );
};

export default TestimonialsSection;
