import React, { useEffect, useState } from "react";
import { heroBgSlides } from "../../../../constants";
import useInterval from "../../../../hooks/useInterval";
import { Button } from "../../../../components/ui";
import { Link } from "react-router-dom";

const HeroSection = () => {
  const [bgSlides, setBGSlides] = useState([]);
  const [currentBGSlideIndex, setCurrentBGSlideIndex] = useState(0);
  const [prevBGSlideIndex, setPrevBGSlideIndex] = useState(null);

  useInterval(() => {

    setPrevBGSlideIndex(currentBGSlideIndex);
    const nextIndex =
      currentBGSlideIndex >= bgSlides.length - 1 ? 0 : currentBGSlideIndex + 1;
    setCurrentBGSlideIndex(nextIndex);
  }, bgSlides.length ? 6000 : null);

  useEffect(() => {
    setBGSlides(heroBgSlides);
    setCurrentBGSlideIndex(0);
  }, []);

  return (
    <main className="relative w-full overflow-hidden">
      <div className="relative h-full overflow-hidden">

        {prevBGSlideIndex !== null && (
          <div
            className="absolute inset-0 bg-cover bg-[bottom_-3rem_left_-30rem] bg-no-repeat 
                       transition-opacity duration-1000 ease-out 
                       md:bg-[bottom_-3rem_left_-37rem] lg:bg-center"
            style={{
              backgroundImage: `url(${
                bgSlides[prevBGSlideIndex]?.background || "/home_bg.svg"
              })`,
              opacity: 0,
              willChange: "opacity",
            }}
          />
        )}

        <div
          className="absolute inset-0 bg-cover bg-[bottom_-3rem_left_-30rem] bg-no-repeat 
                     transition-opacity duration-1000 ease-out 
                     md:bg-[bottom_-3rem_left_-37rem] lg:bg-center"
          style={{
            backgroundImage: `url(${
              bgSlides[currentBGSlideIndex]?.background || "/home_bg.svg"
            })`,
            opacity: 1,
            willChange: "opacity",
          }}
        />

        <div className="relative z-10 px-6 py-8 md:px-10 md:py-20 xl:p-36">
          <div className="mt-20 h-[300px] md:h-[500px]">
            <div className="flex flex-col space-y-20 text-white transition-all duration-1000 ease-out md:space-y-40">
              <div className="flex animate-slide_up flex-col gap-2 lg:w-[50%]">
                <span className="text-3xl font-bold leading-tight md:text-[40px] lg:text-[50px]">
                  {bgSlides[currentBGSlideIndex]?.title ||
                    "Find quality service vendors and "}
                </span>
                <span className="text-3xl font-bold leading-tight text-lightblue md:text-[40px] lg:text-[50px]">
                  {bgSlides[currentBGSlideIndex]?.text || " Get Your Work Done"}
                </span>
                <span className="text-3xl font-bold italic leading-tight md:text-[40px] lg:text-[50px]">
                  {bgSlides[currentBGSlideIndex]?.subtext || " ...Effortlessly"}
                </span>
                <Link to="/coming-soon">
                  <Button
                    children="Get Started"
                    border="none"
                    className="mt-7 w-fit text-sm font-medium md:mt-10 md:text-base"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="absolute bottom-8 left-0 right-0 mx-auto flex items-center justify-center gap-2 pb-4">
            {bgSlides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentBGSlideIndex(index)}
                className={`h-[10px] w-[10px] rounded-full border border-white 
                            transition-all duration-300 ease-linear 
                            hover:bg-white/50 focus:outline-none ${
                              currentBGSlideIndex === index && "bg-white"
                            }`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </main>
  );
};

export default HeroSection;
