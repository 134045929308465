import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Back, Clock, LocationTwo, Star } from "../../../../../../../../constants/images";
import { setShowAgent } from "../../../../../../../../redux/slice/modalOverlay";
import { Button } from "../../../../../../../../components";
import VerificationModal from "../verification-modal";
import { formatDateSuffix } from "../../../../../../../../utils/getDaySuffix";
import { profileicon } from "../../../../../../../../../constants/images";

const AgentTableDetails = ({
  userInfo,
  selectedValue,
  company,
  handleFetchAgentDetails,
  refetch,
  isLoadingDetails,
}) => {
  const accessToken = useSelector((state) => state?.token.states.accessToken);
  const [userDetails, setUserDetails] = useState();
  const [selected, setSelected] = useState();
  const [verification, setShowVerification] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const handleBackClick = () => {
    dispatch(setShowAgent(false));
    refetch();
  };

  useEffect(() => {
    setUserDetails(userInfo);
    setSelected(selectedValue);
  }, [userInfo, selectedValue]);

  const handleShowVerification = () => {
    setShowVerification(!verification);
  };

  const handleConfirmReject = async (action) => {
    setError(null);
    if (!userDetails) {
      setError("User details not available");
      return;
    }

    try {
      const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/review-work-id`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          vendorId: userDetails.id,
          action,
        }),
      });

      const data = await response.json();

      const updatedUserDetails = await handleFetchAgentDetails(selected);

      setUserDetails(updatedUserDetails);
    } catch (error) {
      setError("Failed to verify ID");
    } finally {
      setShowVerification(false);
    }
  };
  if (isLoadingDetails) {
    return <div className="text-lg font-semibold text-secondary">Loading Referral Details...</div>;
  }

  return (
    <div className="relative">
      <div className="relative flex justify-between rounded-[10px] bg-primary px-10 py-12 pt-10 shadow-lg">
        <div className="flex w-3/4 flex-col gap-y-5">
          <div className="flex items-center justify-start gap-x-2">
            <img src={Back} alt="back" className="hover:cursor-pointer" onClick={handleBackClick} />
            <span className="text-base font-medium text-[#181818]">Back</span>
          </div>
          <div className="flex items-center gap-x-4">
            <img
              src={userDetails?.profile_url || profileicon}
              className="w-[75px] rounded-[50%]"
              alt=""
            />
            <div className="flex flex-col">
              <span className="text-lg font-semibold text-secondary">
                {selected?.name} - {userDetails?.business_name}
              </span>
              <span className="text-base font-semibold text-lightgray">
                {userDetails?.specialty}
              </span>
              <div className="flex items-center gap-x-1 text-sm">
                <img src={Star} alt="rating" />
                <span className="font-semibold text-[#181818]">
                  {userDetails?.average_overall_rating ?? "no ratings"}
                </span>
                {/* <span className="font-normal text-lightgray">({userDetails.count})</span> */}
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-xl font-semibold text-secondary">
              {userDetails?.service_description}
            </span>
            <span className="text-sm font-medium text-secondary">Business hours</span>
            <div className="flex flex-col gap-y-2 pt-2">
              <div className="flex items-center gap-x-2">
                <img src={Clock} alt="time" />
                <span>N/A</span>
                <span>N/A</span>
              </div>
              <div className="flex items-center gap-x-2">
                <img src={Clock} alt="time" />
                <span>N/A</span>
                <span>N/A</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-sm font-medium text-secondary">Address</span>
            <div className="flex items-center gap-x-1">
              <img src={LocationTwo} alt="location" />
              <span className="text-base font-semibold text-secondary">
                {userDetails?.business_address ?? "N/A"}
              </span>
            </div>
          </div>
          <div className="flex w-3/4 flex-col">
            <span className="text-sm text-lightgray">Description</span>
            <span className="font-medium text-[#181818] lg:text-lg">
              {userDetails?.service_description ?? "N/A"}
            </span>
          </div>
          <div className="flex flex-col items-start gap-y-2">
            <span className="text-sm text-lightgray">Catalog</span>
            {userDetails?.catalog_url ? (
              <div>
                {JSON.parse(userDetails?.catalog_url).map((url) => (
                  <img src={url} key={url} className="w-[200px]" alt="attachment" />
                ))}
              </div>
            ) : (
              <span className="text-sm text-lightgray">No catalog available</span>
            )}
          </div>
          <div className="flex flex-col items-start gap-y-1">
            <span className="text-sm font-medium text-secondary">Referral information</span>
            <span className="text-sm text-secondary">
              Referred by:{" "}
              <span className="text-base font-semibold text-secondary">{company} Agency</span>
            </span>
            <div className="text-sm text-secondary">
              Work ID:{" "}
              {userDetails?.workid_status === "pending" ? (
                <span className="text-base font-medium text-[#FF0000]">Unverified</span>
              ) : userDetails?.workid_status === "incomplete" ? (
                <span className="text-base font-medium text-[#21A601]">Incomplete</span>
              ) : userDetails?.workid_status === "rejected" ? (
                <span className="text-base font-medium text-[#FF0000]">Rejected</span>
              ) : (
                <span className="text-base font-medium text-[#21A601]">Verified</span>
              )}
            </div>
            {userDetails?.workid_url ? (
              <img src={userDetails?.workid_url} className="w-[200px]" alt="passport" />
            ) : (
              <span className="text-sm text-lightgray">No work ID available</span>
            )}

            {error && <span className="text-red-500">{error}</span>}
            {userDetails?.workid_status === "pending" && (
              <Button onClick={handleShowVerification} className="mt-10">
                Verify ID
              </Button>
            )}
          </div>
        </div>
        <div className="flex w-1/2 flex-col gap-y-10 px-20 py-10">
          <div className="h-fit w-full rounded-[5px] border border-[#AEAEAE] px-4">
            <div className="flex flex-col">
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Join Date</span>
                <span className="text-base font-semibold text-secondary">
                  {formatDateSuffix(selected?.join_date) ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">User ID</span>
                <span className="text-base font-semibold text-secondary">
                  {selected?.user_id ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Phone number</span>
                <span className="text-base font-semibold text-secondary">
                  {selected?.phoneNumber ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Email</span>
                <span className="text-base font-semibold text-secondary">
                  {selected?.email ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Gender</span>
                <span className="text-base font-semibold capitalize text-secondary">
                  {selected?.gender ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Location</span>
                <span className="text-base font-semibold text-secondary">
                  {selected?.location ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Category</span>
                <span className="text-base font-semibold text-secondary">
                  {selected?.service_category ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Specialty</span>
                <span className="text-base font-semibold text-secondary">
                  {userDetails?.specialty ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Years of experience</span>
                <span className="text-base font-semibold text-secondary">
                  {userDetails?.years_of_experience ?? "N/A"}
                </span>
              </div>
              <hr className="w-full" color="#AEAEAE" />
              <div className="flex w-full justify-between py-2">
                <span className="text-sm font-normal text-lightgray">Base price rate</span>
                <span className="text-base font-semibold text-secondary">
                  {userDetails?.base_rate_amount ? `₦${userDetails?.base_rate_amount}` : "N/A"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {verification && (
          <div className="fixed left-[35%] right-[0] z-50">
            <VerificationModal
              handleConfirmReject={handleConfirmReject}
              onClick={handleShowVerification}
              value={selected}
              details={userDetails}
            />
          </div>
        )}
      </div>
      {verification && (
        <div className="fixed bottom-0 left-0 right-0 top-0 z-20 w-screen bg-black bg-opacity-25" />
      )}
    </div>
  );
};

export default AgentTableDetails;
