import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setActiveProject, setActiveTable } from "../../../../redux/slice/modalOverlay";
import CancelledIcon from "../../../../assets/images/cancelled/";
import CompletedIcon from "../../../../assets/images/completed/";
import VendorIcon from "../../../../assets/images/vendors/";
import ConsumerIcon from "../../../../assets/images/consumers/";
import OngoingIcon from "../../../../assets/images/ongoing/";

const ProjectCard = ({
  totalOngoingProjects,
  totalCanceledRequests,
  totalCompletedProjects,
  totalConsumer,
  totalVendor,
}) => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState("");
  const [active, setActive] = useState("project");

  const dashboardProjects = [
    {
      id: 0,
      title: "Ongoing Projects",
      content: "Ongoing",
      value: totalOngoingProjects,
      image: <OngoingIcon />,
      month: "This month",
      increment: "22",
    },
    {
      id: 1,
      title: "Completed Projects",
      value: totalCompletedProjects,
      content: "Completed",
      image: <CompletedIcon />,
      month: "This month",
      increment: 20,
    },
    {
      id: 2,
      title: "Cancelled Projects",
      value: totalCanceledRequests,
      content: "Cancelled",
      image: <CancelledIcon />,
      month: "This month",
      increment: 25,
    },
    {
      id: 3,
      title: "Registered Vendors",
      value: totalVendor,
      image: <VendorIcon />,
      content: "Vendors",
      month: "This month",
      increment: 49,
    },
    {
      id: 4,
      title: "Registered Consumers",
      value: totalConsumer,
      content: "Consumers",
      image: <ConsumerIcon />,
      month: "This month",
      increment: 1.9,
    },
  ];

  const handleActive = (project) => {
    if (selectedProject === project) {
      setSelectedProject("");
      dispatch(setActiveProject(""));
      dispatch(setActiveTable(active));
    } else {
      setSelectedProject(project);
      dispatch(setActiveProject(project));
      dispatch(setActiveTable("project"));
    }
  };
  const handleProjectChange = () => {
    if (selectedProject === "Vendors") {
      dispatch(setActiveTable("Vendors"));
    } else if (selectedProject === "Consumers") {
      dispatch(setActiveTable("Consumers"));
    }
  };

  useEffect(() => {
    handleProjectChange();
  }, [selectedProject]);

  const toggleActiveTable = () => {
    setActive((prevTable) => (prevTable === "project" ? "transaction" : "project"));
  };
  return (
    <div className="float-end ml-auto flex w-full grid-cols-5 justify-between gap-x-2 pb-14">
      {dashboardProjects.map((project) => (
        <div
          className={`w-fit rounded-[10px] px-2 py-6 shadow-lg transition-all ease-in hover:scale-105 hover:cursor-pointer ${selectedProject === project.content ? "bg-lightblue text-primary" : "bg-primary"}`}
          key={project.id}
          onClick={() => {
            setSelectedProject(project.content);
            toggleActiveTable();
            handleActive(project.content);
          }}
        >
          <div className="flex w-full justify-between gap-x-4">
            <div className="flex flex-col gap-y-2">
              <span
                className={`text-xs font-medium text-lightgray md:text-sm ${selectedProject === project.content ? "text-primary" : "text-lightgray"}`}
              >
                {project.title}
              </span>
              <span
                className={`text-base font-semibold md:text-xl ${selectedProject === project.content ? "text-primary" : "text-secondary"}`}
              >
                {project.value}
              </span>
            </div>
            <div className="flex flex-col items-end gap-y-1">
              {project.image}
              <span
                className={`text-xs font-bold md:text-sm ${
                  selectedProject === project.content ? "text-white" : ""
                } ${
                  project.title === "Ongoing Projects"
                    ? "text-[#623CEA]"
                    : project.title === "Completed Projects"
                      ? "text-[#EA3C6D]"
                      : project.title === "Cancelled Projects"
                        ? "text-[#FF392B]"
                        : project.title === "Registered Projects"
                          ? "text-[#279F51]"
                          : project.title === "Registered Consumers"
                            ? "text-[#FFA000]"
                            : ""
                }`}
              >
                + {project.increment} %
              </span>
              <span
                className={`text-xs font-medium ${selectedProject === project.content ? "text-primary" : "text-lightgray"}`}
              >
                {project.month}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;
