import { useState, useMemo } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Dropdown } from "../../../../../constants/images";
import { parseProjectDates } from "../../../../../utils/parsedProject";
import { filterProjectDataByDateRange } from "../../../../../utils/dateFilter";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import "react-datepicker/dist/react-datepicker.css";
const ProjectChart = ({
  totalOngoingProjects,
  totalCanceledRequests,
  totalCompletedProjects,
  onGoingJobs,
  completedJobs,
  cancelledJobs,
}) => {
  const [selectedPeriod, setSelectedPeriod] = useState("This week");
  const [openSelect, setOpenSelect] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const projects = [...onGoingJobs, ...completedJobs, ...cancelledJobs];

  const parsedProjects = useMemo(() => parseProjectDates(projects), [projects]);

  const filteredProjects = useMemo(() => {
    if (selectedPeriod === "Custom range" && startDate && endDate) {
      return parsedProjects.filter(
        (project) =>
          new Date(project.start_date) >= startDate && new Date(project.start_date) <= endDate,
      );
    }
    const dateRange =
      selectedPeriod === "This week" ? "week" : selectedPeriod === "This month" ? "month" : "year";
    return filterProjectDataByDateRange(parsedProjects, dateRange);
  }, [parsedProjects, selectedPeriod, startDate, endDate]);

  const chartData = useMemo(() => {
    const result = {};
    filteredProjects.forEach((project) => {
      const dateKey = project?.start_date.toLocaleDateString("en-GB");
      if (!result[dateKey]) {
        result[dateKey] = { name: dateKey, ongoing: 0, completed: 0, cancelled: 0 };
      }
      result[dateKey][project?.status] += 1;
    });
    return Object.values(result);
  }, [filteredProjects]);

  const handleSelect = () => setOpenSelect((prev) => !prev);

  return (
    <div className="mx-auto mb-10 flex  w-full overflow-auto rounded-[10px] bg-primary py-4">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between px-10 py-5">
          <span className="font-medium text-secondary md:text-lg">Projects</span>
          <div className="relative flex items-center gap-x-2">
            <div
              onClick={handleSelect}
              className="flex w-[190px] items-center justify-between rounded-[5px] border border-[#AEAEAE] px-5 py-2 text-base font-medium text-lightgray hover:cursor-pointer"
            >
              <span>{selectedPeriod}</span>
              <img
                src={Dropdown}
                alt="open"
                className={`${openSelect && "-rotate-90 transition-all ease-in-out"}`}
              />
            </div>
            {openSelect && (
              <div className="absolute left-0 top-[100%] w-full animate-slide_up rounded-[10px] bg-primary px-3 py-4">
                <div className="flex flex-col items-center gap-y-2 text-base font-medium text-lightgray">
                  {["This week", "This month", "This year", "Custom range"].map((period) => (
                    <span
                      key={period}
                      className={`hover:cursor-pointer ${period === "Custom range" ? "text-lightblue" : ""}`}
                      onClick={() => {
                        setSelectedPeriod(period);
                        setOpenSelect(false);
                      }}
                    >
                      {period}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

          {selectedPeriod === "Custom range" && (
            <div className="mt-2 flex flex-col items-center gap-y-2">
              <DatePicker
                selected={startDate}
                onChange={setStartDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                placeholderText="Start Date"
                className="rounded border px-3 py-1"
              />
              <DatePicker
                selected={endDate}
                onChange={setEndDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                placeholderText="End Date"
                className="rounded border px-3 py-1"
              />
            </div>
          )}
        </div>
        <hr className="w-full px-0" color="#AEAEAE" />

        <div className="flex w-full gap-x-8 p-10">
          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#279F51]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Ongoing projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalOngoingProjects}
              </span>
            </div>
          </div>

          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#623CEA]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Completed projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalCompletedProjects}
              </span>
            </div>
          </div>

          <div className="flex w-3/4 flex-col px-6 py-6 shadow-lg transition-all ease-in hover:scale-105">
            <div className="h-[16px] w-[16px] rounded-[50%] bg-[#C12B00]" />
            <div className="flex w-full items-center justify-between gap-x-4">
              <span className="text-xs font-normal text-secondary md:text-sm">
                Cancelled projects
              </span>
              <span className="font-semibold text-secondary md:text-2xl">
                {totalCanceledRequests}
              </span>
            </div>
          </div>
        </div>

        <BarChart
          width={1200}
          height={300}
          data={chartData}
          margin={{ top: 20, right: 50, left: 50, bottom: 50 }}
        >
          <CartesianGrid vertical={false} />
          <XAxis dataKey="name" tick={{ angle: -45, textAnchor: "end" }} />
          <YAxis />
          <Tooltip />
          <Bar dataKey="ongoing" barSize={20} stackId="a" fill="#279F51" />
          <Bar dataKey="completed" barSize={20} stackId="a" fill="#623CEA" />
          <Bar dataKey="cancelled" barSize={20} stackId="a" fill="#C12B00" />
        </BarChart>
      </div>
    </div>
  );
};

ProjectChart.propTypes = {
  totalOngoingProjects: PropTypes.arrayOf(PropTypes.object),
  totalCanceledRequests: PropTypes.arrayOf(PropTypes.object),
  totalCompletedProjects: PropTypes.arrayOf(PropTypes.object),
  onGoingJobs: PropTypes.arrayOf(PropTypes.object),
  completedJobs: PropTypes.arrayOf(PropTypes.object),
  cancelledJobs: PropTypes.arrayOf(PropTypes.object),
};
export default ProjectChart;
