import React from "react";
import {appstore, mobileA, playstore} from "../../../constants/images"
import { mobileB } from "../../../constants/images";
import { Link } from "react-router-dom";

const BannerSection = () => {
  return (
    <div className="lg:px-40 lg:py-16 md:px-12 p-8">
      <div className=" bg-[#E6F5F9] lg:h-[500px] md:h-[350px] h-[545px] lg:px-2 px-12 pt-10 lg:flex lg:flex-row md:flex md:flex-row flex-col lg:justify-evenly md:justify-evenly overflow-hidden lg:w-full w-full">
        <div className="">
          <div className="text-base text-lightblue md:text-lg font-medium my-4 md:mt-4 mt-2">"Solutions Expertly Rendered"</div>
          <h1 className="lg:w-[567px] w-[281px] lg:text-[40px] text-[20px] font-[600] text-[#002D3A] lg:leading-[60px] leading-[30px] lg:mb-36 mb-10">
            Stay connected to work anytime, anywhere with our mobile application
          </h1>
          <div className="flex gap-2 mb-10">
            <Link to="https://apps.apple.com/gb/app/gywde/id6602887701" target="_blank" className="cursor-pointer">
              <img src={appstore} alt="" className="md:w-[6rem] lg:w-[9rem]"></img>
            </Link>
            <Link to="https://play.google.com/store/apps/details?id=com.gywd.animation" target="_blank" className="cursor-pointer">
              <img src={playstore} alt="" className="md:w-[6rem] lg:w-[9rem]"></img>
            </Link>
          </div>
        </div>
        <div className="relative lg:w-[500px] md:w-[50%] w-[350] items-center justify-center">
         <div className=""><img src={mobileA} alt="" className="lg:w-[265.98px] w-[180.48px]  "/></div>
         <div className="absolute lg:bottom-[-5vh] md:bottom-[-5vh] bottom-0 lg:left-[11.5vw] md:left-[15.5vw] left-[27vw] z-10 "><img src={mobileB} alt="" className="lg:w-[265.98px] w-[180.48px] lg:h-[380px] "/></div> 
        </div>
      </div>
      
    </div>
  );
};

export default BannerSection;
