import { useEffect, useState } from "react";
import ProjectTable from "./_components/project/projects-table";
import { useSelector } from "react-redux";
import TransactionTable from "./_components/transaction/transactions-table";
import VendorTable from "./_components/vendor/table";
import ConsumerTable from "./_components/consumer/table";

const Table = ({
  onGoingJobs,
  completedJobs,
  cancelledJobs,
  registeredConsumer,
  registeredVendor,
  transactionData,
  setTransactionData,
}) => {
  const [activeTable, setActiveTable] = useState("");
  const showTable = useSelector((state) => state.modalOverlay.states.activeTable);
  useEffect(() => {
    if (activeTable) {
      setActiveTable(showTable);
    }
  }, [showTable]);

  useEffect(() => {
    setActiveTable("transaction");
  }, []);

  return (
    <div>
      {activeTable === "transaction" ? (
        <TransactionTable
          transactionData={transactionData}
          setTransactionData={setTransactionData}
        />
      ) : activeTable === "project" ? (
        <ProjectTable
          onGoingJobs={onGoingJobs}
          completedJobs={completedJobs}
          cancelledJobs={cancelledJobs}
        />
      ) : activeTable === "Consumers" ? (
        <ConsumerTable registeredConsumer={registeredConsumer} />
      ) : activeTable === "Vendors" ? (
        <VendorTable registeredVendor={registeredVendor} />
      ) : null}
    </div>
  );
};

export default Table;
