export const requestMoreEvidenceAPI = async (disputeId, accessToken) => {
  const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/more-evidence`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ disputeId }),
  });

  if (!response.ok) {
    throw new Error("Failed to request more evidence");
  }

  return response.json();
};
