import React from "react";

import {
  sigUploginImage,
  exploreServicesImage,
  findPerfectImage,
  bookWithinImage,
  path2,
} from "../../../../../constants/images";
const HowitWorksCardA = () => {

  
  return (
    <div className="">
      <div className="mx-7 rounded-[20px] bg-primary py-10 lg:mx-40">
        <div className="flex flex-col px-4 md:px-10 lg:px-20">
          <div className="flex items-start lg:gap-14">
            <div>
              <span className="text-lg font-semibold text-secondary md:text-[20px] lg:text-[25px]">
                If you want to get your work done
              </span>
              <div className="mt-2 h-[4px] w-1/2 rounded-r-[10px] bg-lightblue" />
            </div>
            <img src={path2} alt="spiral" className="-mr-120 mt-4" />
          </div>
          <div className="flex flex-col">
            {/* each item */}
            <div className="flex flex-col w-[80%] mx-auto items-center justify-between py-16 md:flex-row">
              <img src={sigUploginImage} alt="sign up" />
              <div className="flex flex-col items-start gap-2 md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  01
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Sign up or log in
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Create your GYWDE account by providing basic information and
                    fill in essential details about your services, background,
                    and expertise or log in if you already have an account.
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col w-[80%] mx-auto items-center justify-between py-3 md:flex-row">
              <div className="order-last flex flex-col items-start gap-2 md:order-first md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  02
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Explore services
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Browse through a diverse range of services categorized to
                    meet your specific needs. Whether it's home repairs, online
                    projects, or professional services
                  </span>
                </div>
              </div>
              <img
                src={exploreServicesImage}
                alt="sign up"
                className="order-first md:order-last"
              />
            </div>

            <div className="flex flex-col items-center justify-between w-[80%] mx-auto py-16 md:flex-row">
              <img src={findPerfectImage} alt="sign up" />
              <div className="flex flex-col items-start  gap-2 md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  03
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Find the perfect match
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Review detailed profiles of vendors, including their
                    expertise, ratings, and customer reviews.
                  </span>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-between py-3 md:flex-row w-[80%] mx-auto">
              <div className="order-last flex flex-col  items-start gap-2 md:order-first md:flex-row md:gap-4 lg:w-[800px]">
                <span className="font-semibold text-lightblue lg:text-[40px]">
                  04
                </span>
                <div className="flex flex-col items-start gap-3">
                  <span className="font-semibold text-secondary md:text-[20px]">
                    Book with confidence
                  </span>
                  <span className="font-normal text-lightgray md:text-lg">
                    Select your preferred vendor and schedule the service at a
                    time convenient for you in a secure process.
                  </span>
                </div>
              </div>
              <img
                src={bookWithinImage}
                alt="sign up"
                className="order-first md:order-last"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowitWorksCardA;
