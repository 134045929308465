import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../../../components";
import { Spinner, Upload } from "../../../../../../constants/images";
import { setAddNewDiscount } from "../../../../../../redux/slice/modalOverlay";

const AddNewDiscount = ({ fetchAllDiscount }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [fileError, setFileError] = useState("");
  const accessToken = useSelector((state) => state?.token.states.accessToken);

  const [details, setDetails] = useState({
    name: "",
    percentage: "",
    usage_frequency: "",
    discount_code: "",
    valid_until: "",
    email: "",
  });

  const [errorState, setErrorState] = useState({
    nameError: "",
    percentageError: "",
    usage_frequencyError: "",
    discount_codeError: "",
    valid_untilError: "",
    emailError: "",
    uploadError: "",
  });

  const [success, setSuccess] = useState(false);

  const handleForm = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let isError = false;
    const errors = {
      nameError: "",
      percentageError: "",
      usage_frequencyError: "",
      discount_codeError: "",
      valid_untilError: "",
      emailError: "",
    };

    if (!details.name) {
      isError = true;
      errors.nameError = "Please enter a discount name";
    }
    if (!details.discount_code) {
      isError = true;
      errors.discount_codeError = "Please enter a discount code";
    }
    if (!details.percentage) {
      isError = true;
      errors.percentageError = "Please enter discount percentage";
    }
    if (details.percentage < 1 || details.percentage > 100) {
      isError = true;
      errors.percentageError = "Percentage must be between 1 and 100";
    }
    if (!details.usage_frequency) {
      isError = true;
      errors.usage_frequencyError = "Please enter usage frequency";
    }
    if (!details.valid_until) {
      isError = true;
      errors.valid_untilError = "Please enter validity";
    }

    if (details.email && !isValidEmail(details.email)) {
      isError = true;
      errors.emailError = "Please enter a valid email address";
    }

    setErrorState({ ...errorState, ...errors });
    return isError;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedFormats = ["image/jpeg", "image/png", "image/svg+xml"];

    if (file && allowedFormats.includes(file.type)) {
      setSelectedFile(file);
      setFileName(file.name);
      setFileError("");
    } else {
      setSelectedFile(null);
      setFileName("");
      setFileError("Invalid file format. Please select a JPG, JPEG, PNG, or SVG file.");
    }
  };

  const handleAddDiscount = async () => {
    const error = validate();

    if (!selectedFile) {
      setFileError("Please select an image");
      return;
    }

    if (!error) {
      setLoading(true);
      try {
        const payload = { ...details };

        if (!details.email) {
          delete payload.email;
        }

        const response = await fetch(`${import.meta.env.VITE_BASE_URL}/admin/discount`, {
          method: "POST",
          body: JSON.stringify(payload),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();

        await updateDiscount(data.discountId, selectedFile);

        setSuccess(true);
        setTimeout(() => {
          fetchAllDiscount();
          setSuccess(false);
          setLoading(false);
          dispatch(setAddNewDiscount(false));
        }, 3000);
      } catch (error) {
        setErrorState({ ...errorState, uploadError: "An error occurred. Please try again." });
        setLoading(false);
      }
    }
  };

  const updateDiscount = async (discountId, image) => {
    const formData = new FormData();
    formData.append("discount", image);

    try {
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/admin/discount/${discountId}/image`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (!response.ok) {
        let errorData;
        try {
          errorData = await response.clone().json();
          throw new Error(errorData.message || "Unknown error occurred");
        } catch (jsonError) {
          const text = await response.clone().text();
          throw new Error(`HTTP error! status: ${response.status}, message: ${text}`);
        }
      }

      const data = await response.json();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClose = () => {
    dispatch(setAddNewDiscount(false));
  };

  return (
    <div className="relative h-[80vh] animate-slide_up overflow-y-auto rounded-tl-[30px] rounded-tr-[30px] bg-white py-8 lg:w-[529px] lg:rounded-[5px]">
      <div className="absolute right-5 top-2 text-2xl hover:cursor-pointer" onClick={handleClose}>
        x
      </div>
      <div className="flex flex-col gap-y-3 px-8">
        <span className="py-2 text-start text-xl font-semibold text-secondary">Add Discount</span>
        <div className="flex flex-col">
          <label htmlFor="discount_name" className="text-base font-normal text-[#181818]">
            Name
          </label>
          <input
            type="text"
            name="name"
            id="discount_name"
            placeholder="Name the discount"
            onChange={handleForm}
            value={details.name}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {errorState.nameError && (
            <span className="text-start text-sm text-red-500">{errorState.nameError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="email" className="text-base font-normal text-[#181818]">
            Referrer Email <span className="text-sm text-gray-500">(optional)</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter referrer's email"
            onChange={handleForm}
            value={details.email}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {errorState.emailError && (
            <span className="text-start text-sm text-red-500">{errorState.emailError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="discount_percentage" className="text-base font-normal text-[#181818]">
            Percentage
          </label>
          <input
            type="text"
            name="percentage"
            id="discount_percentage"
            max={100}
            min={1}
            placeholder="Input the discount percentage"
            onChange={handleForm}
            value={details.percentage}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {errorState.percentageError && (
            <span className="text-start text-sm text-red-500">{errorState.percentageError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="usage" className="text-base font-normal text-[#181818]">
            Usage frequency
          </label>
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              name="usage_frequency"
              id="usage"
              placeholder="Input the usage frequency"
              onChange={handleForm}
              value={details.usage_frequency}
              className="w-1/2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
            />
            <span className="text-base font-normal text-lightgray">time(s)</span>
          </div>
          {errorState.usage_frequencyError && (
            <span className="text-start text-sm text-red-500">
              {errorState.usage_frequencyError}
            </span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="discount_code" className="text-base font-normal text-[#181818]">
            Discount code
          </label>
          <input
            type="text"
            name="discount_code"
            id="discount_code"
            placeholder="Set code"
            onChange={handleForm}
            value={details.discount_code}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 outline-none"
          />
          {errorState.discount_codeError && (
            <span className="text-start text-sm text-red-500">{errorState.discount_codeError}</span>
          )}
        </div>
        <div className="flex flex-col">
          <label htmlFor="validity" className="text-base font-normal text-[#181818]">
            Valid until
          </label>
          <input
            type="date"
            name="valid_until"
            id="validity"
            placeholder="Set validity date"
            onChange={handleForm}
            value={details.valid_until}
            className="w-full rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 text-lightgray outline-none"
          />
          {errorState.valid_untilError && (
            <span className="text-start text-sm text-red-500">{errorState.valid_untilError}</span>
          )}
        </div>

        <div className="flex flex-col gap-y-2">
          <span className="text-base font-normal text-[#181818]">Image</span>
          <div>
            <label
              htmlFor="addNew"
              className="flex flex-col items-center justify-center gap-y-2 rounded-[5px] border border-[#5F5F5F] border-opacity-50 px-2 py-3 text-lightblue transition-all ease-in hover:scale-95 hover:cursor-pointer"
            >
              <img src={Upload} alt="addNew" />
              <span> Upload Image</span>
              <span>{fileName}</span>
            </label>
            <input
              type="file"
              name="addNew"
              id="addNew"
              className="hidden"
              accept=".jpg,.jpeg,.png,.svg"
              onChange={handleFileChange}
            />
          </div>
          {fileError && <span className="text-center text-sm text-red-500">{fileError}</span>}
        </div>
        {errorState.uploadError && (
          <span className="text-start text-sm text-red-500">eee{errorState.uploadError}</span>
        )}

        <div className="mt-10 w-full">
          <Button
            className="flex w-full items-center justify-center"
            onClick={handleAddDiscount}
            disabled={loading}
          >
            {loading ? <img src={Spinner} alt="loading" className="w-[5%]" /> : "Add Discount"}
          </Button>
        </div>
      </div>

      {success && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="rounded-lg bg-white p-5 shadow-lg">
            <span className="text-xl font-semibold">Success!</span>
            <p className="mt-2">The discount has been added successfully.</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewDiscount;
