import { useEffect, useState } from "react";
import { Testimonials } from "../../../../../../constants";
import { quote, quote1 } from "../../../../../../constants/images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

const Card = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <div className="relative w-full">
      <Swiper
        modules={[Autoplay, Pagination, EffectCoverflow]}
        slidesPerView={isMobile ? 1 : 3}
        spaceBetween={30}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{ clickable: true }}
        effect="coverflow"
        coverflowEffect={{
          rotate: 10,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
          slideShadows: false,
        }}
        className="w-full"
      >
        {Testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.name} className="flex justify-center text-white">
            <div
              className="animate-bounceIn relative mt-20 w-full max-w-md rounded-tl-[50px] rounded-tr-[50px] bg-[#002D3A] p-8 shadow-lg transition-transform duration-700 
                         ease-in-out md:px-10 md:py-10"
            >
              <div className="pointer-events-none absolute inset-0 opacity-50 blur-md transition-all duration-700 group-hover:opacity-100 group-hover:blur-none"></div>

              <div className="relative mt-[-6rem] flex flex-col items-center px-3 md:px-0">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="h-32 w-32 rounded-full border-8 border-primary"
                />
                <div className="my-2 flex flex-col items-center space-y-1 text-nowrap bg-primary bg-opacity-20 px-6 py-1 text-center">
                  <span className="font-semibold lg:text-lg">{testimonial.name}</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="flex w-full justify-start py-3">
                    <img src={quote1} alt="quote" />
                  </div>
                  <span className="text-center">{testimonial.text}</span>
                  <div className="flex w-full justify-end py-3">
                    <img src={quote} alt="quote" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <style>{`
        @keyframes bounceIn {
          0% {
            transform: translateY(30px) scale(0.9);
            opacity: 0.8;
          }
          50% {
            transform: translateY(-10px) scale(1);
            opacity: 1;
          }
          100% {
            transform: translateY(0) scale(1);
          }
        }
        .animate-bounceIn {
          animation: bounceIn 0.8s ease-in-out;
        }
      `}</style>
    </div>
  );
};

export default Card;
